<template>
  <div>
    <bread-crumb :crumbData="['产品档案', '编辑']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm"
      style="padding: 20px; width: 96%">
      <el-form-item label="商品名称：" prop="product_name">
        <el-input v-model="ruleForm.product_name"></el-input>
      </el-form-item>
      <el-form-item label="卖点说明：">
        <el-input v-model="ruleForm.description"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="7">
          <el-form-item label="计量单位：" prop="unit" class="widthCol">
            <el-input v-model="ruleForm.unit" placeholder="支，箱，桶"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="商品分类：" prop="cid" class="widthCol">
            <el-select v-model="ruleForm.cid" clearable placeholder="请选择">
              <el-option v-for="(item, index) in cidDatas" :key="index" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="商品简称：" class="widthCol">
            <el-input v-model="ruleForm.nike_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="商品属性：" class="widthCol">
            <el-input v-model="ruleForm.attributes" placeholder="微甜"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="规格型号：" required> </el-form-item>
      <el-table :data="ruleForm.tableData" border style="width: 100%; margin-bottom: 20px; margin-top: -10px">
        <el-table-column label="货号/条码" width="140px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.number" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-upload :headers="{ token: token }" name="file" class="avatar-uploader"
              :action="$http.defaults.baseURL + '/uploads_images/upload'" :show-file-list="false" list-type="picture"
              :auto-upload="true" ref="uploadRef" :on-success="handleAvatarSuccess.bind(this, scope.$index)">
              <img v-if="scope.row.imageUrl" :src="scope.row.imageUrl" class="avatar" />
              <el-button size="small" type="primary" class="uploadBtu">添加图片</el-button>
            </el-upload>
          </template>
        </el-table-column>
        <el-table-column label="规格" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.specifications" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="颜色" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.color" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="市场价" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.market_price" style="width: 80%" v></el-input>
          </template>
        </el-table-column>
        <el-table-column label="销售价" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sales_price" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="成本价" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.cost_price" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="库存" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.total_inventory" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP1价" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip1" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP2价" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip2" style="width: 70%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP3价" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip3" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP4价" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip4" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="VIP5价" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.vip5" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="月销量" width="75px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.monthly_sales" style="width: 80%"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="44">
          <template>
            <el-tooltip content="清空" placement="top">
              <el-button type="text" size="mini" @click="handleEmpty">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <span class="table_spriteChart_empty"></span>
                </div>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label="品牌类型：">
        <el-select v-model="ruleForm.tid" clearable placeholder="请选择">
          <el-option v-for="(item, index) in brandTypes" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="商品类型：">
            <el-select v-model="ruleForm.product_type" clearable placeholder="请选择">
              <el-option v-for="(item, index) in product_types" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="销售策略：">
            <el-select v-model="ruleForm.sales_strategy" clearable placeholder="请选择">
              <el-option v-for="(item, index) in sales_strategys" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="运费模版：">
            <el-select v-model="ruleForm.freight_template" clearable placeholder="请选择">
              <el-option v-for="(item, index) in freight_templates" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="最小起订量：">
            <el-input v-model="ruleForm.minimum" placeholder="0"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="保质期(天)：">
            <el-input v-model="ruleForm.quality_guarantee" placeholder="0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="重量(Kg)：">
            <el-input v-model="ruleForm.weight" placeholder="0"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="主供应商：">
        <el-select v-model="ruleForm.supplier" clearable placeholder="选择供应商">
          <el-option v-for="(item, index) in suppliers" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="商品标签：">
            <el-select v-model="ruleForm.product_label" multiple placeholder="请选择">
              <el-option v-for="(item, index) in product_labels" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item>
            <el-checkbox v-model="ruleForm.is_putaway" :true-label="0" :false-label="1">暂不上架</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="排序优先级：">
        <el-input v-model="ruleForm.sort"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交 </el-button>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>
export default {
  // props: ["passPageId"],
  data() {
    return {
      ruleForm: {
        product_name: "",
        description: "",
        unit: "",
        cid: "",
        nike_name: "",
        attributes: "",
        tid: "",
        tableData: [{}],
        product_type: "",
        sales_strategy: "",
        freight_template: "",
        minimum: "",
        quality_guarantee: "",
        weight: "",
        supplier: "",
        product_label: [],
        is_putaway: "",
        commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
        sort: "0",
      },
      rules: {
        product_name: [
          { required: true, message: "请填写内容", trigger: "blur" },
        ],
        unit: [{ required: true, message: "请填写内容", trigger: "blur" }],
        cid: [{ required: true, message: "请选择", trigger: "blur" }],
        model: [
          {
            required: true,
            message: "请填写内容",
            trigger: "change",
          },
        ],
      },
      commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
      product_labels: ["精选", "新品", "推荐"].map((el, index) => { return { label: el, value: index } }),
      cidDatas: [],
      brandTypes: [],
      product_types: ["普通商品", "同城商品", "虚拟商品", "赠品"].map((el, index) => { return { label: el, value: index } }),
      sales_strategys: [],
      freight_templates: [],
      suppliers: [],
      bucket_returns: [],
    };
  },
  async mounted() {

    this.token = localStorage.getItem("token") || sessionStorage.getItem("token");
    (await this.$http.post("/Goods_Category/lst", { commercial_id: this.commercial_id })).data.data.map((item) => {
      // console.log(item)
      this.cidDatas.push({ id: item.id, label: item.classify_name });
      item.children.forEach(el => {
        this.cidDatas.push({ id: el.id, label: item.classify_name + "->" + el.classify_name })
      })
    });
    this.freight_templates = (await this.$http.post("/system/freight_template/lst", { commercial_id: this.commercial_id })).data.data.map(el => { return { label: el.template_name, value: el.id } })
    this.suppliers = (await this.$http.post("/inventory/supplier/lst", { commercial_id: this.commercial_id })).data.data.map((item) => {
      return { label: item.supplier_name, value: item.id };
    });
    this.brandTypes = (await this.$http.post("/Brand_Type/lst", { commercial_id: this.commercial_id })).data.data.map((item) => {
      return { label: item.column_name, value: item.id };
    });
    this.sales_strategys = (await this.$http.post("/system/policy_set/lst", { commercial_id: this.commercial_id })).data.data.map((item) => {
      return { label: item.policy_name, value: item.id };
    });


    // console.log(this.cidDatas)
    // this.pageInfo();
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },

    // 页面数据
    // pageInfo() {
    //   console.log(this.$pageInfoTil)
    //   this.$pageInfoTil.pageInfo("/Goods_Category/lst").then((data) => {
    //     this.cidDatas = data.map((item) => {
    //       return { id: item.id, label: item.classify_name };
    //     });
    //   });
    //   this.$pageInfoTil.pageInfo("/Brand_Type/lst").then((data) => {
    //     this.brandTypes = data.map((item) => {
    //       return { id: item.id, label: item.name };
    //     });
    //   });
    // },
    // 上传文件
    handleAvatarSuccess(index, response, file) {
      // this.thumbnail = response;
      this.$set(this.ruleForm.tableData[index], "thumbnail", response.path);
      this.$set(
        this.ruleForm.tableData[index],
        "imageUrl",
        URL.createObjectURL(file.raw)
      );
    },
    handleEmpty() {
      this.ruleForm.tableData = [{}];
    },
    // 提交表单
    submitForm(formName) {
      this.ruleForm.tableData.forEach((item) => {
        delete item.imageUrl;
      });
      if (this.ruleForm.product_label) {
        this.ruleForm.product_label = this.ruleForm.product_label.join(",");
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.ruleForm, this.ruleForm.tableData);
          this.ruleForm = { ...this.ruleForm, ...this.ruleForm.tableData[0] };
          console.log(this.ruleForm);
          this.$http
            .post(
              "/product/add",
              this.ruleForm,
            )
            .then(() => {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.ruleForm = {};
              this.ruleForm.tableData = [{}];
              this.onClose(0);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .cell {
  padding: 0 !important;
}

.widthCol {
  width: 90%;
}

/deep/ .avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar {
  width: 54px;
  height: 54px;
  display: block;
  // padding: 5px;
  margin: 0 auto;
  border: 1px solid #dddddd;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 10px !important;
}

/deep/ .el-input__inner {
  padding: 0 5px;
}
</style>
